import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import DosageCalculator from '../components/dosage-calculator/dosage-calculator'
import {buildBreadcrumbsItem} from '../helpers'
import styles from '../scss/page.scss'

class ProductFinder extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Product Finder | Honest Paws'
    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'Product Finder | Honest Paws'
      },
      children: [],
      disallowIndexing: true
    }

    this.breadcrumbs = [
      buildBreadcrumbsItem('Product Finder', this.props.location.href)
    ]
  }


  render() {
    return (
      <Layout containerClassName={`page page-help-center`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden" location={this.props.location} >
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <section className="product-finder-section">
          <div className="container">
            <div className="row">
              <DosageCalculator fullWidth={true}/>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ProductFinder

export const ProductFinderQuery = graphql`
  query ProductFinderQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`